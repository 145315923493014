<template>
  <div>
    <v-card elevation="0" style="width:100%">
      <v-card-title style="padding:16px 0">
        HTML/Text Block
        <v-spacer></v-spacer>
        <v-menu v-model="menuActive">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon style="margin-top:-.5em">
              <v-icon>far fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-dialog v-model="editActive" width="800">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-action>
                    <v-icon small>fal fa-pencil</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Edit Block Settings</v-list-item-title>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title>Editing: {{ inputVal.label }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-select :value="inputVal.input" :items="inputTypes" label="Input Type" @change="(val) => $emit('change', { input: val })"></v-select>
                    </v-col>
                  </v-row>
                  <visibility-rules
                    :input="input"
                    :input-list="inputList"
                    @change="(val) => $emit('change', val)"
                  ></visibility-rules>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="editActive = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-list-item @click="$emit('addBefore')">
              <v-list-item-action>
                <v-icon small>fal fa-plus-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>Add Input Before</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('addAfter')">
              <v-list-item-action></v-list-item-action>
              <v-list-item-title>Add Input After</v-list-item-title>
              <v-list-item-avatar>
                <v-icon small>fal fa-plus-circle</v-icon>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item @click="$emit('remove')">
              <v-list-item-action>
                <v-icon small>fal fa-times-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>Remove Input</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text style="padding:0 0 16px">
        <html-editor v-model="info"></html-editor>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { ref, reactive, watch } from '@vue/composition-api'
export default {
  name: 'html-editor-input',
  components: {
    htmlEditor: () => import('@/components/forms/HTMLEditor'),
    visibilityRules: () => import('./visibility-rules')
  },
  props: {
    input: {
      type: Object,
      required: true
    },
    inputList: Array
  },
  setup (props, { root, emit }) {
    const inputVal = reactive({
      label: props.input.label,
      input: props.input.input,
      info: props.input.info,
      ifs: props.input.ifs || []
    })
    const info = ref(props.input.info || '')
    watch(() => props.input, (val) => {
      info.value = val.info || ''
    })
    watch(info, () => {
      emit('change', { info: info.value })
    })

    const menuActive = ref(false)
    const editActive = ref(false)
    const selectedTab = ref(0)
    const inputTypes = ref([
      { text: 'Text Field', value: 'text' },
      { text: 'Email Address', value: 'email' },
      { text: 'Number', value: 'number' },
      { text: 'Multi-line Text Field', value: 'textarea' },
      { text: 'Select Box', value: 'select' },
      { text: 'Combo Box', value: 'combobox' },
      { text: 'AutoComplete', value: 'autocomplete' },
      { text: 'Radio Group', value: 'radio' },
      { text: 'Checkbox', value: 'checkbox' },
      { text: 'Switch', value: 'switch' },
      { text: 'Slider', value: 'slider' },
      { text: 'Range Slider (Dual Slider)', value: 'rangeSlider' },
      { text: 'Date Picker', value: 'datePicker' },
      { text: 'Time Picker', value: 'timePicker' },
      { text: 'File Upload', value: 'file' },
      { text: 'HTML/Text Block', value: 'html-block' },
      { text: 'Repeatable Block', value: 'repeatable-block' }
    ])

    return {
      inputVal,
      info,
      menuActive,
      editActive,
      inputTypes,
      selectedTab
    }
  }
}
</script>
